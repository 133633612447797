
#sidebar {
    min-width: 200px;
    max-width: 200px;
    background-color: var(--sk_nav);
    color: var(--sk_text_inverse);
}
#sidebar.active {
    min-width: 70px;
    max-width: 70px;
    text-align: center;
}

#sidebar > ul {
    position:fixed;
    top:100px;
    width:200px;
}

#sidebar > div {
    position:fixed;
}

#sidebar .sidebar-header {
    padding-top: 10px;
    padding-left: 60px;
    padding-right: 20px;
    padding-bottom: 35px;
}
#sidebar.active .sidebar-header {
    padding: 0px;
    padding-top: 10px;
}

#sidebar.active .sidebar-header h3 {
    display: none;
}

#sidebar .sidebar-header p {
    display: none;
    font-size: 1.8rem;
}
#sidebar.active .sidebar-header p {
    display: block;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1rem;
    display: block;
    text-align: left;
}
#sidebar ul li a:hover {
    background-color: #ff9a00!important;;
}
#sidebar ul div li a {
    font-size: 0.85rem;
}

#sidebar ul li.active {
    font-weight: bold;
    color: var(--sk_selected);
    background-color: #bababa!important;
}

.color-nav {
    background-color: #bababa!important;
}

@media (max-width: 992px) {
    #sidebar {
        min-width: 70px;
        max-width: 70px;
        text-align: center;
    }

    #sidebar > ul {
        width:70px;
    }

    #sidebar .sidebar-header {
        padding: 0px;
        padding-top: 10px;
    }
    #sidebar .sidebar-header h3 {
        display: none;
    }
    #sidebar .sidebar-header p {
        display: block;
        padding-left: 15px;
    }

    #sidebar ul li a {
        width:100%;
    }

    #sidebar ul li>a span {
        display:none;
    }
}