#walletTitle {
    font-family: OpenSans;
    font-weight: 300;
    font-size: 32px;
    color: var(--main-link-color);
    margin-top: 0;
    margin-bottom: 0;
    line-height: 60px;
}

.container__nav__bo {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
}

.container__nav__bo .profilName {
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    color: var(--footer-selected-color);
    margin-right: 30px;
}

.container__nav__bo svg {
    color: #FF9E17;
}

.nameUsers {
    display: flex;
    align-items: center;
    justify-content: center;
}

.logoApp {
    height: 25px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: auto;
    margin-right: auto;
}

.container__header:nth-child(2) {
    max-width: 60%;
    width: 60%;
}

.container__header {
    max-width: 20%;
    width: 20%;
}

.container__logo div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}


/*mobile*/
@media (max-width: 480px) {
    .nameUsers  {
        display: flex;
        flex-direction: column;
    }
    .container__logo .logoApp {
        height: 14px !important;
        padding-left: 20px;
    }
    .nameUsers {
        justify-content: flex-end;
        align-items: center;
    }


    .container__header:nth-child(2) {
        max-width: 50%;
        width: 50%;
    }

    .container__header {
        max-width: 25%;
        width: 25%;
    }

    #walletTitle {
        font-size: 24px !important;
    }

    .container__nav__bo  .profilName {
        margin-right: 0;
    }

    .container__header .actionLink {
        width: auto;
        padding-top: 0;
    }

    .container__nav__bo .profilName {
        font-size: 14px!important;
    }
}

/*tablette*/
@media (max-width: 970px) and (min-width: 480px) {
    .container__header:nth-child(2) {
        max-width: 33%;
        width: 33%;
    }

    .container__header {
        max-width: 33%;
        width: 33%;
    }

    .container__header .actionLink {
        width: auto;
    }
}

