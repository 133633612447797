body {
    background: #f7fbfd;
}
h1 {
    font-weight:bold;
    text-align:center;
}
h1,h2,h3,h4,h5 {
    font-family: OpenSans !important;
}


p {
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    font-family: OpenSans;
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    font-family: OpenSans;
}

i,
span {
    display: inline-block;
    font-family: OpenSans;
}

strong {
    font-family: OpenSans;
}

button {
    font-family: OpenSans;
}

label {
    font-family: OpenSans;
}

div {
    font-family: OpenSans;
}



a.labelDebug {
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline !important;
}

.btncancel {
    margin-left: 18px;
}