@font-face {
    font-family: 'Belluga';
    src: url('../fonts/Belluga.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Clother';
    src: url('../fonts/Clother-Bold.woff2') format('woff2'),
    url('../fonts/Clother-Bold.woff') format('woff'),
    url('../fonts/Clother-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Clother';
    src: url('../fonts/Clother-Regular.woff2') format('woff2'),
    url('../fonts/Clother-Regular.woff') format('woff'),
    url('../fonts/Clother-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Clother';
    src: url('../fonts/Clother-Light.woff2') format('woff2'),
    url('../fonts/Clother-Light.woff') format('woff'),
    url('../fonts/Clother-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Silvana';
    src: url('../fonts/Silvana-Light.woff2') format('woff2'),
    url('../fonts/Silvana-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Silvana';
    src: url('../fonts/Silvana-LightItalic.woff2') format('woff2'),
    url('../fonts/Silvana-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans';
    src: url('../fonts/OpenSans-Light.woff2') format('woff2'),
    url('../fonts/OpenSans-Light.woff') format('woff'),
    url('../fonts/OpenSans-Light.ttf') format('ttf');
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans';
    src: url('../fonts/OpenSans-Regular.woff2') format('woff2'),
    url('../fonts/OpenSans-Regular.woff') format('woff'),
    url('../fonts/OpenSans-Regular.ttf') format('ttf');
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: 'OpenSans';
    src: url('../fonts/OpenSans-Medium.woff2') format('woff2'),
    url('../fonts/OpenSans-Medium.woff') format('woff'),
    url('../fonts/OpenSans-Medium.ttf') format('ttf');
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans';
    src: url('../fonts/OpenSans-SemiBold.woff2') format('woff2'),
    url('../fonts/OpenSans-SemiBold.woff') format('woff'),
    url('../fonts/OpenSans-SemiBold.ttf') format('ttf');
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans';
    src: url('../fonts/OpenSans-Bold.woff2') format('woff2'),
    url('../fonts/OpenSans-Bold.woff') format('woff'),
    url('../fonts/OpenSans-Bold.ttf') format('ttf');
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans';
    src: url('../fonts/OpenSans-ExtraBold.woff2') format('woff2'),
    url('../fonts/OpenSans-ExtraBold.woff') format('woff'),
    url('../fonts/OpenSans-ExtraBold.ttf') format('ttf');
    font-weight: 800;
    font-display: swap;
}