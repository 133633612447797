body {
    background-color: var(--sk_background);
    font-size:0.9em;
    overflow: hidden;
    color: var(--sk_text);

}

h1 {
    font-weight:bold;
    text-align:center;
}

p {
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
}

i,
span {
    display: inline-block;
}


a.labelDebug {
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline !important;
}

.new-profile label {
    display:none;
}