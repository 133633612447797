.fa-download {
    display: inline-block;
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
    color: black;
}

#content .card svg {
    height: 1em;
}

.hiddenRow {
    display:none;
}

.oddTableLine {
    background-color: var(--sk_oddLine);
}

.evenTableLine {
    background-color: var(--sk_evenLine);
}

.selectedTableLine {
    background-color: var(--sk_selected) !important;
}

.new-user {
    display: flex;
    /*margin: 0 15px;*/
    padding: 8px 0;
    align-items: center;
    justify-content: space-between;
}

.new-user input:first-child{
    margin: 0 20px 0 0 ;
}

.submit-new-user {
    background-image: url(../res/newIcone.svg);
    background-repeat: no-repeat;
    height: 14px;
    overflow: visible;
    vertical-align: -0.125em;
    font-size: 0;
    border: 0;
}

.new-user label {
    display: none;
}

body .open {
    display: block;
}

.formthis {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.formthis .form-group {
    width: 100%;
}

.ActiveUserEnable .buttonUserEnable {
    display: none;
}

.ActiveUserEnable .buttonUserDisable {
    display: inline-block;
}
.buttonUserEnable {
    display: inline-block;
}

.buttonUserDisable {
    display: none;
}

.ActiveUserLock .compteUnlock {
    display: inline-block;
}

.ActiveUserLock .compteLock {
    display: none;
}
.compteUnlock {
    display: none;
}

.buttonCompteLock {
    display: inline-block;
}

body .new-user {
    justify-content: space-between;
}

.container__information__users:hover .tooltip {
    display: inline-block;
    opacity: 1;
    top: -77px;
}

.container__information__users {
    position: relative;
}

.container__information__users .arrow {
    top: 50%;
}

