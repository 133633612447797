#header {
    padding-top: 10px;
    padding-bottom: 30px;
    width: 100%;
    max-width:100%;
    z-index:20;
}

.user {
    width: 300px;
    z-index:30;
    font-weight: bold;
    padding-top: 5px;
    padding-left: 20px;
    padding-bottom: 7px;

    background-color: var(--sk_nav);
    color: var(--sk_text_inverse);
}

#walletTitle {
    font-family: OpenSans;
    font-weight: 300;
    font-size:40px!important;
    color: var(--main-link-color);
    margin-top: 0;
    margin-bottom: 0;
    line-height: 60px;
}

.container__nav__bo .profilName {
    font-size: 20px!important;
}