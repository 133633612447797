:root {
    --sk_nav: #00558a;
    --sk_nav_hover: #591b56;
    --sk_nav_selected: #00558a;
    --sk_inactive: #cccccc;
    --sk_active: #112299;
    --sk_selected: #ffff00;
    --sk_icon: #5ea3c0;
    --sk_emphase: #504747;
    --sk_text: #000000;
    --sk_text_inverse: #ffffff;
    --sk_background: #f7fbfd;
    --sk_oddLine: #f0f0f0;
    --sk_evenLine: transparent;
}

/* 00558a   */
.actionLink {
    cursor: pointer;
}

.button,
.button:hover {
    background-color: var(--sk_active);
    color: var(--sk_text_inverse);
}

.link,
.link:hover {
    font-style: italic;
    text-decoration: underline !important;
}

.hideBeforeHover {
    display: none;
}

.showOnHover:hover:not(.whileAssetFolderLink) .hideBeforeHover {
    display: inline;
}

.selected {
    background-color: var(--sk_selected);
}

.hidden {
    display:none !important;
}

.wrapper {
    display: flex;
    align-items: stretch;
}

.fullSize {
    max-width: 100%;
}

.fullHeight {
    height: 90vh !important;
}

#page {
    width: 100%;
    min-height: 100vh;
}


#content {
    margin-top:10px;
    padding: 20px;
    width: 100%;
}

.popover {
    max-width: 50%;
}

.navigator {
    height:100%;
}

.assetUploader {
    position: absolute;
    bottom: 0px;
    width: 100%;
}

.asset {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.uploadComponent {
    outline: 2px dashed rgb(255, 158, 23);
    outline-offset: -10px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .4s linear;
    transition: outline-offset .15s ease-in-out, background-color .4s linear;
    font-size: 1.25rem;
    background-color: white;
    position: relative;
    width: 100%;
    font-family: OpenSans;
    font-weight: 300;
    color: var(--main-link-color);
}

.uploadComponent:hover {
    background-color: rgba(255, 158, 23, 0.5);
}

.uploadComponent strong {
    color: #000;
}

.uploadComponent input[type="file"] {
    width: 0.1px;
    height: 0.1px;
}

.uploadComponent label {
    width: 100%;
    cursor: pointer;
    padding-top: 20px;
    padding-bottom: 10px;
}

.card-body.container.fullSize {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 150px);
}

@media (max-width: 992px) {
    .desktop-only {
        display:none !important;
    }
}

:root {
    --ck-z-default: 1051 !important;
    --ck-z-modal: calc( var(--ck-z-default) + 999 ) !important;;
}

.modal-backdrop {
    display: none;
}

.card-header {
    background: transparent !important;
}
