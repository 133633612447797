
.productCardContent{
    display: flex;
    flex-direction: column;
    height: 35vh;
    color: #3c3c3b;
    align-items: center;
    justify-content: space-around;
    background-color: #eee;

}
.productCardPicture{
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    flex-grow: 1;
    width: 100%;
}

.productCardContent{
    font-family: OpenSans;
}

.typeTitle{
    font-family: OpenSans !important;
    font-weight: bold;

}

.subTypeTitle{
    font-family: OpenSans;
}

.header__stock {
   display: flex;
    justify-content: space-between;
    align-items: center;
}

.addbutton {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.addbutton #newButtonScan {
    max-width: 200px;
    width: 150px;
    margin: inherit;
    margin-top: 0;
}

@media only screen and (max-width: 700px) {
    .modelsContainer{
        justify-content: center;
    }
}

.productCardTitle {
    border-bottom: rgb(255, 158, 23) solid 1px;
}
