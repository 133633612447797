.productCardButtons {
    display: none !important;
}
.hiddenHover{
    display: none;
}
body{
    overflow: scroll !important;
}

.productCard:hover .hiddenHover{
    display: flex;
}

.productCard:hover .productCardButtons {
    display: flex !important;
    flex-direction: column;
    position: absolute;
    right: 4px;
}

.productCard,
.productCardShop {
    cursor: pointer;
    position: relative;
}

.stockSection{
    border-bottom: 0.5px solid rgb(255, 158, 23);
    margin-bottom: 1%;
}

@media (max-width: 970px) and (min-width: 200px) {
    .stockSection {
        padding-bottom: 30px;
    }
}

.disabledSection{
    border-color: #999999;
}

.selectedModel .productCard{
    border: 2px solid rgb(255, 158, 23);
    border-radius: 5px;
}

.btnBo{
    background: rgb(255, 158, 23);
    color: white;
}

.btnBo:hover{
    color: white;

}
.btn:focus {
    box-shadow: none!important;
}

.stockSectionContent{
    display: flex;
    align-items: center;
}

.stockSection .step{
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.stockSection.disabledSection .step{
    color: #999999;
}

.productCard span {
    display: none;
}
.productCard:hover  span {
    display: block !important;
}

.productCardHeader {
    display: none !important;
}

.productCard:hover  .productCardHeader {
    display: flex !important;
}

.productCard .actionLink,
.productCardShop .actionLink {
    padding: 0;
    margin: 0;
    margin-bottom: 5px;
}

.productCardButtons {
    margin-top: 10px;
}

.filters .dropdown {
    max-width: 250px;
}

.header__shop__stock .filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
}

.stockText {
    margin-right: auto;
    padding: 0.5rem;
}

.productCard,
.productCardShop {
    background-color: #eee;
    transition: transform 0.5s;
}
.productCard::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 2s cubic-bezier(0.165, 0.84, 0.44, 1);
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
    content: "";
    opacity: 0;
    z-index: -1;
}
.productCard:hover::after, .productCardContent:focus::after {
    opacity: 1;
}

.actionLink:hover svg{
    color: rgb(255, 158, 23);
}

.actionLink {
    width: 100%;
}

@media (max-width: 970px) and (min-width: 200px) {
    .stockSectionContent .justify-content-end {
        flex-direction: column;
    }
    .form-group {
        width: 100%;
        max-width: 100%;
    }
    .step {
        margin-top: 10px;
    }
}

.nbSeal {
    font-size: 1.5rem;
    font-weight: bold;
}

.nbSealGreen {
    color: green;
}

.nbSealRed {
    color: red;
}

.previewImg {
    cursor: pointer;
    height: 10rem;
}

.ui.selection.active.dropdown .menu {
    border-color: rgb(255, 158, 23)!important;
}

.ui.selection.active.dropdown {
    border-color: rgb(255, 158, 23)!important;
}

.form-control:focus {
    border-color: rgb(255, 158, 23);
    box-shadow: 0 0 0 0.2rem rgba(255, 158, 23, 0.25);
}
.custom-select:focus {
    border-color: rgb(255, 158, 23);
    box-shadow: 0 0 0 0.2rem rgba(255, 158, 23, 0.25);
}

body  {
    overflow-x: hidden!important;
}

.custom-control-input:checked ~ .custom-control-label::before {
    background-color: rgb(255, 158, 23);
    border-color: rgb(255, 158, 23);
    box-shadow: 0 0 0 0.2rem rgba(255, 158, 23, 0.25);
}

.custom-control-input:active ~ .custom-control-label::before {
   background-color: rgba(255, 158, 23, 0.25)!important;
    border-color: rgb(255, 158, 23)!important;
}

.custom-control-input[disabled] ~ .custom-control-label::before {
    background-color: white!important;
    box-shadow: none;
    border-color: rgba(255, 158, 23, 0.25)!important;
}

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}


.fullSize {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 150px);
}


.customSpinner {
    border-color: rgb(255, 158, 23);
    border-right-color: transparent;
}

.flash {
    background-color: white;
    border: black solid 2px;
    border-radius: 5px;
    width: 50px;
    height: 50px;
    color: black;
    font-size: 25px;
}

.flashOn {
    border-color: rgb(255, 158, 23);
    color: rgb(255, 158, 23);
}