.ModalQRCode .modal-dialog {
    transform: translate(0,0) !important;
}

.QrTitle {
    font-family: OpenSans;
    font-weight: 300;
    font-size: 32px;
    color: var(--main-link-color);
    margin-top: 0;
    margin-bottom: 0;
    line-height: 60px;
    margin-bottom: 22px;
}

.ModalQRCode .chevronBack {
    width: 100%;
}

.ModalQRCode .modal-backdrop {
    display: block !important;
}

#newButtonScan {
    min-height: 40px;
    /*color: var(--footer-selected-color);*/
    font-size: 12px;
    letter-spacing: 1.2px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0;
    font-family: OpenSans;
    align-items: center;
    background-color: #FF9E17;
    text-transform: uppercase;
    margin-top: 20px;
    cursor: pointer;
    border: none;
    max-width: 50%;
    margin: 0 auto;
    margin-bottom: 25px;
}

#newSerialNumber {
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 50%;
    margin: 0 auto;
    margin-top: 25px
}
#inputName {
    /*margin-bottom: 25px;*/
    /*display: flex;*/
    /*justify-content: center;*/
    /*width: 100%;*/
    /*max-width: 50%;*/
    /*margin: 0 auto;*/
    /*margin-top: 25px*/
}
.buttonaddPorduct {
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 50%;
    margin: 0 auto;
    margin-top: 25px;
    border: 0;
    background: #FF9E17;
    min-height: 23px;
    align-items: center;
    font-size: 11px;
}

.ModalQRCode .closeModal {
    position: absolute;
    right: 15px;
    top: 10px;
}

.qrcodesvg section div {
    padding-top: 60% !important;
}

@media (max-width: 600px) {
    .qrModal {
        margin: auto;
    }
}

.topQrModal {
    margin-bottom: 5px;
    min-height: 60px;
    display: flex;
    justify-content: space-between;
}