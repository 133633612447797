#content .card svg {
    height: 1em;
}

.oddTableLine {
    background-color: var(--sk_oddLine);
}

.evenTableLine {
    background-color: var(--sk_evenLine);
}

.selectedTableLine {
    background-color: var(--sk_selected) !important;
}

.new-group,
.update-group {
    display: flex;
    /*margin: 0 15px;*/
    padding: 8px 0;
    align-items: center;
    justify-content: space-between;
}

.new-group input:first-child{
    margin: 0 20px 0 0 ;
}

.submit-new-group {
    background-image: url(../res/newIcone.svg);
    background-repeat: no-repeat;
    height: 14px;
    overflow: visible;
    vertical-align: -0.125em;
    font-size: 0;
    border: 0;
}

.new-group label {
    display: none;
}

body .open {
    display: block;
}

.formthis {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.formthis .form-group {
    width: 100%;
}

.buttonCompteLock {
    display: inline-block;
}

body .new-group {
    justify-content: space-between;
}

.switchBtn:focus {
    border: none;
    box-shadow: none;
}

.switchBtn {
    transition: none;
}

.btnFilter {
    background-color: white!important;
    border: 1px solid black!important;
}


